import React from "react"

const Map = () => (
    <div className="map">
        <div className="inset-0 h-96 bg-gray-300">
            <iframe width="100%"
                    height="100%"
                    frameBorder="0"
                    title="map"
                    marginHeight="0"
                    marginWidth="0"
                    scrolling="no"
                    src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJ886ZQGRreUcRWdwOve2bKHg&zoom=17&&key=AIzaSyCra5cYzYrJ3nn4joeUDvDEi9SaGAV24kk"
                    style={{filter: "grayscale(1) contrast(1.2) opacity(0.8)"}}/>
        </div>
    </div>
)

export default Map
