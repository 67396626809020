import React from "react"
import {graphql, useStaticQuery} from "gatsby";
import Img from "gatsby-image";

const Shop = () => {
    const data = useStaticQuery(graphql`
    query {
      image1: file(relativePath: { eq: "story/IMG_0145_1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 300, maxHeight: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      },
       image2: file(relativePath: { eq: "story/IMG_1160.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 300, maxHeight: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      },
    }
  `)

    return (
        <section className="text-gray-600 body-font bg-gray-50">
            <div className="container px-5 py-24 mx-auto flex flex-wrap text-center lg:text-left">
                <div className="flex flex-wrap -mx-4 mt-auto mb-auto lg:w-1/2 sm:w-2/3 content-start sm:pr-10">
                    <div className="w-full sm:p-4 px-4 mb-6">
                        <h2 className="title-font font-medium text-3xl mb-4 text-gray-800 ">Il nostro negozio</h2>
                        <div className="w-12 h-1 bg-pink-500 rounded mt-2 mb-4 mx-auto lg:-mx-0"/>
                        <p className="leading-relaxed text-lg mb-2">
                            Nel nostro spaccio aziendale potrai scegliere tra una vasta gamma di tessuti e imbottiture per realizzare il piumino o la trapunta su misura per te.
                            Vieni a trovarci! Ti aspettiamo in <span className="font-medium text-pink-500">Via del Forno 3 a Pianzano</span>.
                        </p>
                        <p className="leading-relaxed text-lg"> Siamo <strong className="text-green-600">aperti</strong> dal lunedì al venerdì <strong>
                            dalle 8:30 alle 12:00 e dalle 15:00 alle 19:00</strong> /
                            <strong>sabato solo mattina</strong> dalle 8:30 alle 12:00 o il pomeriggio <strong>solo su prenotazione</strong> (chiama <a className="text-pink-500" href="tel: +39 0438 430273">+39 0438 430273</a>)
                        </p>
                    </div>
                    <div className="p-4 w-1/2">
                        <h2 className="title-font font-medium text-3xl text-gray-900">100+</h2>
                        <p className="leading-relaxed">Hotel serviti</p>
                    </div>
                    <div className="p-4 w-1/2">
                        <h2 className="title-font font-medium text-3xl text-gray-900">65+</h2>
                        <p className="leading-relaxed">Anni di esperienza</p>
                    </div>
                </div>
                <div className="lg:w-1/2 sm:w-1/3 w-full rounded-lg overflow-hidden mt-6 sm:mt-0 flex flex-wrap items-center">
                    <div className="md:p-2 p-1 w-1/2">
                        <Img className="rounded" fluid={data.image1.childImageSharp.fluid}/>
                    </div>
                    <div className="md:p-2 p-1 w-1/2">
                        <Img className="rounded" fluid={data.image2.childImageSharp.fluid}/>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Shop
