import React from 'react'
import {graphql, useStaticQuery} from 'gatsby'

import BackgroundImage from 'gatsby-background-image'

const BackgroundSection = ({className}) => {
    const data = useStaticQuery(
        graphql`
      query {
        desktop: file(relativePath: { eq: "hero-pattern.jpg" }) {
          childImageSharp {
            fixed {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `
    )

    // Set ImageData.
    const imageData = data.desktop.childImageSharp.fixed

    return (
        <BackgroundImage
            Tag="div"
            className={className}
            fixed={imageData}
            style={{
                position: '',
                height: "400%",
                width: "400%",
                top: "-25%",
                left: "-100%",
                backgroundSize: "800px auto",
                backgroundPosition: '',
                backgroundRepeat: '',
            }}
        >
        </BackgroundImage>
    )
}


export default BackgroundSection