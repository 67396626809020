import React from "react"

const Contact = () => (
    <section className="text-white text-opacity-75 body-font relative bg-gray-700 py-24">
        <div className="flex flex-col text-center w-full mb-12">
            <h1 className="sm:text-3xl text-3xl title-font mb-4">Contattaci</h1>
            <div className="w-12 h-1 bg-pink-500 rounded mt-2 mb-4 mx-auto"/>
            <p className="lg:w-2/3 mx-auto leading-relaxed text-base ">Per qualsiasi informazione non esitare a contattarci.</p>
        </div>
        <div className="lg:w-1/3 w-10/12  mx-auto bg-white p-10 rounded shadow-2xl">
            <form className="mx-auto" action="https://getform.io/f/f92f529d-00a6-4df0-8369-baa8967fff5b" method="POST">
                <div className="flex flex-wrap -m-2">
                    <div className="p-2 w-full lg:w-1/2">
                        <div className="relative">
                            <label htmlFor="name" className="leading-7 text-sm text-gray-600">Nome</label>
                            <input type="text" id="name" name="name"
                                   placeholder="Inserisci il tuo nome"
                                   className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-pink-500 focus:bg-white focus:ring-2 focus:ring-pink-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"/>
                        </div>
                    </div>
                    <div className="p-2 w-full lg:w-1/2">
                        <div className="relative">
                            <label htmlFor="email" className="leading-7 text-sm text-gray-600">Email</label>
                            <input type="email" id="email" name="email"
                                   placeholder="Inserisci il tuo indirizzo Email"
                                   required
                                   className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-pink-500 focus:bg-white focus:ring-2 focus:ring-pink-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"/>
                        </div>
                    </div>
                    <div className="p-2 w-full">
                        <div className="relative">
                            <label htmlFor="message" className="leading-7 text-sm text-gray-600">Messaggio</label>
                            <textarea id="message" name="message"
                                      placeholder="Scrivi il tuo messaggio"
                                      required
                                      className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-pink-500 focus:bg-white focus:ring-2 focus:ring-pink-200 h-32 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"/>
                        </div>
                    </div>
                    <div className="p-2 w-full">
                        <button className="flex mx-auto text-white bg-pink-500 border-0 py-2 px-8 focus:outline-none hover:bg-pink-600 rounded text-lg">Invia messaggio</button>
                    </div>
                </div>
            </form>
        </div>
    </section>
)

export default Contact
