import React from "react"
import BackgroundSection from "./BackgroundSection"
import Loadable from '@loadable/component'
import logo from '../images/logo.svg'

const LoadableTypeWriter = Loadable(() =>
    import('typewriter-effect')
)


const Hero = () => {
    const scrollTo = (className) => {
        import("react-scroll")
            .then((scroll) => {
                scroll.scroller.scrollTo(className, {
                    duration: 800,
                    delay: 0,
                    smooth: "easeInOutQuart",
                })
            })
    }
    const scrollToStory = () => scrollTo('story')
    const scrollToMap = () => scrollTo('map')
    return (
        <div className="min-h-screen relative bg-white overflow-hidden">
            <BackgroundSection className="hidden lg:block absolute scroll-bg"/>
            <div className="z-10 relative bg-white lg:max-w-3xl lg:w-full lg:min-h-screen">
                <svg className="h-full hidden lg:block absolute right-0 inset-y-0 h-full w-48 text-white transform translate-x-1/2"
                     fill="currentColor"
                     viewBox="0 0 100 100"
                     preserveAspectRatio="none"
                     aria-hidden="true">
                    <polygon points="50,0 100,0 50,100 0,100"/>
                </svg>
                <div className="relative pt-6 px-4 sm:px-6 lg:px-8">
                    <nav className="relative flex items-center justify-between sm:h-10 lg:justify-start" aria-label="Global">
                        <div className="flex items-center flex-grow flex-shrink-0 lg:flex-grow-0">
                            <div className="flex items-center justify-between w-full md:w-auto">
                                <a href="/">
                                    <span className="sr-only">Logo</span>
                                    <img className="h-8 w-auto sm:h-10" alt="logo" src={logo}/>
                                </a>
                            </div>
                        </div>
                    </nav>
                </div>
                <div className="mt-10 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
                    <div className="sm:text-center lg:text-left">
                        <h1 className="text-4xl tracking-tight font-extrabold text-gray-700 sm:text-5xl md:text-6xl">
                            Trapuntificio <span className="text-pink-500">Casini</span>
                        </h1>
                        <div className="lg:flex lg:items-center mt-3 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
                            <p className="text-gray-500 mb-3 lg:mb-0">
                                Produzione artigianale e su misura:
                            </p>
                            <div className="text-pink-500 inline lg:ml-2 font-bold">
                                <LoadableTypeWriter options={{
                                    strings: ['piumini', 'trapunte', 'materassi', 'biancheria per la casa'],
                                    autoStart: true,
                                    loop: true
                                }}/>
                            </div>
                        </div>
                        <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
                            <div className="rounded-md shadow">
                                <button onClick={scrollToStory} className="w-full flex items-center justify-center px-14 py-3 border border-transparent text-base font-medium rounded-sm text-white bg-pink-500 hover:bg-pink-500 md:py-4 md:text-lg md:px-10">
                                    Esplora
                                </button>
                            </div>
                            <div className="mt-3 sm:mt-0 sm:ml-3">
                                <button onClick={scrollToMap} className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-sm text-pink-500 bg-pink-100 hover:bg-pink-200 md:py-4 md:text-lg md:px-10">
                                    Dove siamo
                                </button>
                            </div>
                        </div>
                        <h2 className="lg:mt-16 mt-5 title text-gray-400 text-lg bottom-0">Seguici su Facebook:</h2>
                        <a className="text-gray-500 inline-flex mr-auto mb-8" rel="noopener noreferrer" target="_blank" href="https://www.facebook.com/Trapuntificiocasinipianzano/">
                            <svg fill="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-12 h-12 lg:w-16 lg:h-16 bg-gray-200 mt-6 rounded-full lg:p-4 p-2 hover:bg-pink-300 hover:text-pink-500 cursor-pointer" viewBox="0 0 24 24">
                                <path d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z"/>
                            </svg>
                        </a>
                    </div>
                </div>
            </div>
            <BackgroundSection className="absolute scroll-bg"/>
        </div>
    )
}

export default Hero
