import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from '../components/hero'
import Gallery from "../components/gallery";
import Map from "../components/map";
import Contact from "../components/contact";
import Shop from "../components/shop";
import Story from "../components/story";
import Helmet from "react-helmet";

const IndexPage = () => (
    <Layout className="font-sans antialiased text-gray-900 bg-gray-900">
        <Helmet>
            <meta charSet="utf-8"/>
            <title>Home</title>
            <link rel="canonical" href="http://trapuntifiocasini.it/"/>
        </Helmet>
        <SEO title={"Trapuntificio Casini"} description={"Trapunte, piumini, materassi, biancheria per la casa"}/>
        <Hero/>
        <Story/>
        <Shop/>
        <Gallery/>
        <Contact/>
        <Map/>
    </Layout>
)

export default IndexPage
