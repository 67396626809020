import React from "react"
import {graphql, StaticQuery} from "gatsby";
import Img from "gatsby-image";

const Gallery = () => {
    return (
        <section className="text-gray-600 body-font gallery py-16">
            <div className="flex flex-col items-center text-center justify-center lg:w-1/3 w-4/5 mx-auto pb-16">
                <h2 className="title-font mt-4 text-gray-600 text-3xl mb-2">Portfolio</h2>
                <div className="w-12 h-1 bg-pink-500 rounded mt-2 mb-4"/>
                <p className="leading-relaxed text-lg text-gray-600">Abbiamo avuto il privilegio di lavorare e crescere con una clientela esigente e diversificata..</p>
            </div>
            <div className="max-w-5xl m-auto flex flex-wrap justify-start">
                <StaticQuery
                    query={graphql`
                      {
                        allGalleryYaml {
                          edges {
                            node {
                              image {
                                childImageSharp {
                                  fluid(maxWidth: 300, maxHeight: 300){ 
                                    ...GatsbyImageSharpFluid
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    `}
                    render={data => (
                        data.allGalleryYaml.edges.map(({node}) => (
                            <div className="w-full md:w-1/2 lg:w-1/3 flex flex-col mb-8 px-3">
                                <div className="overflow-hidden bg-white rounded-lg shadow hover:shadow-raised hover:translateY-2px transition">
                                    <Img className="w-full" fluid={node.image.childImageSharp.fluid} alt={node.alt}/>
                                </div>
                            </div>
                        ))
                    )}
                />
            </div>
        </section>
    )
}

export default Gallery
